import { createSlice} from "@reduxjs/toolkit";
import { PartyAccessType, PartyCallStatus, PartyInfo, PartyPerson, PartyPersonAudioStatus, PartyPersonJoinStatus, PartyStatus } from "../components/LiveChat/partyComponents/PartyTypes";
import { getTimestamp } from "../components/LiveChat/Timestamp";

type Invitee = {
    playerName: string,
    avatarGlb: string,
    playerRole: string
}

interface partyState {
    partyName: string,
    availableParties: PartyInfo[],
    partyInfo: PartyInfo | null,
    partyInvitee: Invitee,
    selectedPlayer: PartyPerson,
    playerLoggedin: PartyPerson,
    autoAccept: boolean
}

const initialState: partyState = {
    partyName: "test party",
    availableParties: [{
        timestamp: getTimestamp(),
        partyId: "",
        hostPlayerId: "",
        partyName: "redux",
        accessType: PartyAccessType.PUBLIC,
        partyPeople: [],
        partyStatus: PartyStatus.NOT_STARTED,
        hostPeerId: "",
        hostPartyCallStatus: PartyCallStatus.NOT_CONNECTED,
        partyActivities: []   
    }],
    partyInfo: {
        timestamp: getTimestamp(),
        partyId: "",
        hostPlayerId: "",
        partyName: "Test",
        accessType: PartyAccessType.PUBLIC,
        partyPeople: [],
        partyStatus: PartyStatus.NOT_STARTED,
        hostPeerId: "",
        hostPartyCallStatus: PartyCallStatus.NOT_CONNECTED,
        partyActivities: []   
    },
    partyInvitee: {playerName: "test", avatarGlb: "https://models.readyplayer.me/6641ec98e6d7b4328f134d69.glb", playerRole: "test role"},
    selectedPlayer:  {
        playerId: "666",
        audioStatus: PartyPersonAudioStatus.DEFAULT,
        joinStatus: PartyPersonJoinStatus.JOINED,
        partyPersonCallStatus: PartyCallStatus.CONNECTED,
        partyPersonPeerId: null
    },
    playerLoggedin: {
        playerId: "666",
        audioStatus: PartyPersonAudioStatus.DEFAULT,
        joinStatus: PartyPersonJoinStatus.JOINED,
        partyPersonCallStatus: PartyCallStatus.CONNECTED,
        partyPersonPeerId: null
    },
    autoAccept: false
}

export const partySlice = createSlice({
    name:"messages",
    initialState,
    reducers: {
        setPartyName: (state, action) => {
            state.partyName = action.payload
        },
        setAvailableParties: (state, action) => {
            state.availableParties = action.payload
        },
        setPartyInfo: (state, action) => {
            state.partyInfo = action.payload
        },
        setPartyInvitee: (state, action) => {
            state.partyInvitee = action.payload
        }, 
        setSelectedPlayer: (state, action) => {
            state.selectedPlayer = action.payload
        },
        setPlayerLoggedIn: (state, action) => {
            state.playerLoggedin = action.payload
        },
        setAutoAccept: (state, action) => {
            state.autoAccept = action.payload
        }
    }
});

export const { setPartyName, setAvailableParties, setPartyInfo, setPartyInvitee, setSelectedPlayer, setAutoAccept } = partySlice.actions;

export default partySlice.reducer;