import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from "../../store/hooks";
import { cleanMessage } from "../LiveChat/chatWindowComponents/ChatBubble";
import { setPlayer } from "../../store/appUser";

export interface WindowSize {
    width: number,
    height: number
}

type Props = {
    redirect: Function
}


export const AvatarPage: React.FC<Props> = ({ redirect }) => {

    const history = useNavigate();
    let dispatch = useDispatch();
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const [background, setBackground] = useState<string>("none")
    const [hasCookie, setHasCookie] = useState<boolean>(false)
    const [prompt, setprompt] = useState<string>("")
    const [showAvatarPicker, setShowAvatarPicker] = useState(false)
    const [showNamePicker, setShowNamePicker] = useState(true)
    const [selectedMale, setSelectedMale] = useState(false)
    const [selectedFemale, setSelectedFemale] = useState(false)
    const { player } = useAppSelector((state) => state.appUser)
    const circleScalingFactor = Math.min(windowSize.width / 1800, windowSize.height / 1800);


    useEffect(() => {
        if (player.glbUrl) {
            handleShowAvatar(player.glbUrl)
            setHasCookie(true)
        }
    }, [player.glbUrl])

    // useEffect(() => {
    //     let username = Cookies.get("username");
    //     if(username){
    //         dispatch(setAvatarName(username))
    //     }
    // },[])

    useEffect(() => {
        console.log(player)
    }, [player])

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);


    function handleShowAvatar(glb: string) {
        let stateToUpdate = {
            ...player,
            glbUrl: glb,
        };
        dispatch(setPlayer(stateToUpdate))

    }

    function handleAvatarName(name: string) {
        let clean = cleanMessage(name)
        const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
        if(urlRegex.test(clean)){
            clean = clean.replace(urlRegex, "*")
        }
        let stateToUpdate = {
            ...player,
            displayName: clean,
        };
        dispatch(setPlayer(stateToUpdate))
        Cookies.set("username", clean)
    }

    function handleJumpIn() {
        if (player.glbUrl !== null) {
            redirect(true)
        } else {
            setprompt("prompt")
            setTimeout(() => {
                setprompt("")
            }, 2000)
        }
    }


    return (
        <div >
            <div className="dotted dot1 offset" style={{ height: 1700 * circleScalingFactor, width: 1700 * circleScalingFactor }}>

            </div>
            <div className="circle  offset" style={{ height: 1300 * circleScalingFactor, width: 1300 * circleScalingFactor, backgroundColor: background }}>
                <div className="dotted dot1 offset" style={{ height: 1200 * circleScalingFactor, width: 1200 * circleScalingFactor }}></div>
                <img id="logoImage" src={"/images/worldAsOne.png"} alt="no image" className="avatar-logo" height={530 * circleScalingFactor} width={550 * circleScalingFactor} style={{ display: showNamePicker ? "" : "none" }} />
                <div className="avatar-input-area2" style={{ height: 480 * circleScalingFactor, width: 530 * circleScalingFactor, display: showNamePicker ? "" : "none" }}>
                    <h3 style={{ color: '#61dafb' }}>Enter your name</h3>
                    <div>
                        <input
                            className="custom-input"
                            style={{
                                padding: `${2 * circleScalingFactor}rem ${1.5 * circleScalingFactor}rem`,
                                width: '100%'
                            }}
                            placeholder="Name"
                            value={player.displayName ? player.displayName : ""}
                            onChange={e => {
                                handleAvatarName(e.target.value)
                            }}></input>
                    </div>
                    <br />
                    <div style={{ width: '100%' }}>
                        <button
                            disabled={!player.displayName}
                            className='customButton'
                            onClick={() => {
                                console.log(player.displayName)
                                setShowNamePicker(false)
                                setShowAvatarPicker(true)
                            }}
                        > Continue
                        </button>
                    </div>
                </div>
                <div className="avatar-input-area1" style={{ display: showAvatarPicker ? "" : "none" }}>
                    <h3 style={{ color: '#61dafb' }}>Choose your Avatar</h3>
                    <p style={{ fontSize: '65%', color: 'lightgray' }}>Use a predefined Avatar: </p>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <button
                            className={`avatar-btn ${selectedFemale ? "selected-default" : ""}`}
                            style={{
                                padding: `${1.5 * circleScalingFactor}rem ${8.5 * circleScalingFactor}rem`
                            }}
                            onClick={() => {
                                setSelectedFemale(true)
                                setSelectedMale(false)
                                handleShowAvatar("https://models.readyplayer.me/6641ec2fd5d11dcc57df141d.glb")
                            }}
                        >Female</button>
                        <button
                            className={`avatar-btn ${selectedMale ? "selected-default" : ""}`}
                            style={{
                                padding: `${1.5 * circleScalingFactor}rem ${8.5 * circleScalingFactor}rem`
                            }}
                            onClick={() => {
                                setSelectedMale(true)
                                setSelectedFemale(false)
                                handleShowAvatar("https://models.readyplayer.me/66c870b093f9fe518a518f81.glb")
                            }}
                        >Male</button>
                    </div>
                    <div style={{ marginTop: '5%', width: '80%' }}>
                        <p style={{ fontSize: '65%', color: 'lightgray' }}>Build your own: </p>
                        <button
                            className='avatar-btn'
                            style={{
                                padding: `${1.5 * circleScalingFactor}rem ${8.5 * circleScalingFactor}rem`
                            }}
                            onClick={() => history("/mv/editor")}
                        >Start Customising</button>
                    </div>
                    <div style={{ width: '100%', marginTop: '15px' }}>
                        <button
                            disabled={!(!!player.displayName && !!player.glbUrl)}
                            className='customButton'
                            onClick={() => {
                                handleJumpIn()
                            }}
                        > Join
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}