var padLeft = function (text: string, min_length: number, padding_character: string): string {
    if (text.length >= min_length) {
        return text;
    }
    if (typeof padding_character !== "string" || padding_character === null || padding_character.length === 0) {
        padding_character = " ";
    }
    while (text.length < min_length) {
        text = padding_character + text;
    }
    return text;
};

export const getTimestamp = function (): string {
    // yyyy-MM-ddTHH:mm:ss.SSSZ
    var date = new Date();
    var year = date.getUTCFullYear();
    var month = date.getUTCMonth() + 1;
    var day = date.getUTCDate();
    var hour = date.getUTCHours();
    var minute = date.getUTCMinutes();
    var second = date.getUTCSeconds();
    var millisecond = date.getUTCMilliseconds();
    return `${year}-${padLeft(month.toString(), 2, "0")}-${padLeft(day.toString(), 2, "0")}T${padLeft(hour.toString(), 2, "0")}:${padLeft(minute.toString(), 2, "0")}:${padLeft(second.toString(), 2, "0")}.${padLeft(millisecond.toString(), 3, "0")}Z`;
};
