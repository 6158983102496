import { useDispatch } from 'react-redux';
import { setUserMessages } from '../../store/appUser';
import React, { useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import { getTimestamp } from './Timestamp';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { cleanMessage } from './chatWindowComponents/ChatBubble';
import { TypingMessage } from './chatWindowComponents/Enums';

type Props = {
    sendMessageToParent: Function,
    setIsFocused: Function,
    room: string
}

const ChatInput: React.FC<Props> = ({ sendMessageToParent, setIsFocused, room}) => {

    const [newMessage, setNewMessage] = useState("")
    const { player, userMessages, id } = useAppSelector((state) => state.appUser)
    const [showEmojis, setShowEmojis] = useState(false)
    const [cursorPosition, setCursorPosition] = useState(0);
    const [wobble, setWobble] = useState("");
    const dispatch = useDispatch()

    // post the message to the server
    function sendMessage(message: string) {
        let chatMessage = {
            from: player.displayName ? player.displayName : "Guest",
            body: message,
            timestamp: getTimestamp(),
            playerId: id,
            room: room
        }

        try{
        window.STOMP.send("/app/send-chat-message", chatMessage);
        } catch (error) {
            console.log(error)
        }
    }

    // dispatch a message with a guid to notify the socket that a user is typing
    function sendIsTypingMessage(){
        let chatMessage = {
            from: player.displayName,
            body: TypingMessage.IS_TYPING,
            timestamp: getTimestamp(),
            playerId: id
        }
        try{
            window.STOMP.send("/app/send-chat-message", chatMessage);
            } catch (error) {
                console.log(error)
            }
    }

    function sendStoppedTypingMessage(){
        let chatMessage = {
            from: player.displayName,
            body: TypingMessage.STOPPED_TYPING,
            timestamp: getTimestamp(),
            playerId: id
        }
        try{
            window.STOMP.send("/app/send-chat-message", chatMessage);
            } catch (error) {
                console.log(error)
            }
    }

    // useEffect(() => {
    //     let nameCookie = Cookies.get("username")
    //     if (nameCookie) {
    //         dispatch(setAvatarName(nameCookie))
    //     }
    // }, [])

    const checkIfContainsURL = (message: string): boolean => {
        const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
        return urlRegex.test(message);
    };

    // prepare the message guid and add the message to the user messages, this will have to change when we have usernames
    function send() {
        if (newMessage !== "/debug") {
            if(newMessage.length > 0 && !checkIfContainsURL(newMessage)){
                let clean = cleanMessage(newMessage)
                sendMessage(clean)
                let messageHistory: Object[] = [...userMessages]
                messageHistory.push({ msg: clean})
                dispatch(setUserMessages(messageHistory))
                setNewMessage("")
                setCursorPosition(0)
            } else if (checkIfContainsURL(newMessage)) {
                setWobble("wobble")
                setTimeout(() => {
                    setWobble("")
                }, 2000)
            }
        } else {
            sendMessageToParent("debug")
            setCursorPosition(0)
        }

    }

    // listen for return to send message
    function handleKeyDown(event: any) {
        setCursorPosition(event.target.selectionStart + 1);
        if (event.key === 'Enter') {
            sendStoppedTypingMessage()
            send()
            setNewMessage("")
        } else {
            sendIsTypingMessage()
        }
    }
    // handle focus and blur to allow the "Enter" keypress to either open chat or send a message 
    const handleFocus = () => {
        console.log("Handling Focus")
        setIsFocused(true);
    };
    
    const handleBlur = () => {
        console.log("Handling Blur")
        setIsFocused(false);
    };

    // add the emoji from the picker taking note of the cursor position so that the emoji doesnt wipe out the text
    const addEmoji = (emoji: { native: string | any[]; }) => {
        const start = newMessage.substring(0, cursorPosition);
        const end = newMessage.substring(cursorPosition);
        const updatedMessage = start + emoji.native + end;
        setNewMessage(updatedMessage);
        setCursorPosition(cursorPosition + emoji.native.length);
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: 0 }}>
            <div className={`chat-input ${wobble}`} style={{ flexGrow: 1 }}>
                <input
                    onKeyDown={(e) => handleKeyDown(e)}
                    id="chat-input"
                    type="text"
                    autoComplete="off"
                    value={newMessage}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={(e) => {
                        setNewMessage(e.target.value);
                    }}
                />
                <button className="emoji-button" style={{ marginLeft: 0 }} onClick={(e) => { e.stopPropagation(); setShowEmojis(!showEmojis); }}>
                    <SentimentSatisfiedAltIcon />
                </button>
                <div className='emoji-picker'>
                    {showEmojis ? (
                        <Picker
                            data={data}
                            onEmojiSelect={(emoji: any) => addEmoji(emoji)}
                            onClickOutside={() => setShowEmojis(!showEmojis)}
                            theme='dark'
                        />
                    ) : null}
                </div>
                <button
                    className='send-button'
                    onClick={() => {
                        send();
                        setNewMessage("");
                    }}>
                    <img alt="no-Image" src="/images/buttons/button-send.png" width="60" />
                </button>
            </div>
            <img alt="no-Image" src="/images/currency.png" width="60" style={{ marginLeft: '10px', verticalAlign: 'bottom', marginTop: '10px' }} />
        </div>

    )
}

export default ChatInput;