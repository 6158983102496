import ChatBubble from "./chatWindowComponents/ChatBubble";
import ChatInput from "./ChatInput";
import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../store/hooks";
import { UsersOnline } from './chatWindowComponents/UsersOnline';
import "./Chat.css";

type Props = {
    setIsFocused: Function,
    isTyping: string,
    room: string
}

const ChatWindow: React.FC<Props> = ({ setIsFocused, isTyping, room }) => {
    const { messageHistory } = useAppSelector((state) => state.messages);
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const messageRef = useRef(messageHistory);
    const [isScrolledUp, setIsScrolledUp] = useState(false); 
    const [showScrollToBottom, setShowScrollToBottom] = useState(false); 

    // Scroll to bottom when new message is received
    const scrollToBottom = () => {
        const chatContainer = chatContainerRef.current;
        if (chatContainer) {
            chatContainer.scrollTop = chatContainer.scrollHeight;
            setShowScrollToBottom(false); 
        }
    };

    useEffect(() => {
        if (!isScrolledUp) {
            scrollToBottom(); // Only auto-scroll if the user hasn't scrolled up
        } else {
            setShowScrollToBottom(true); // Show the scroll to bottom icon when a new message arrives
        }
    }, [messageHistory]);

    useEffect(() => {
        messageRef.current = messageHistory;
        handleScroll()
    }, [messageHistory]);

    // Detect if user has scrolled up
    const handleScroll = () => {
        const chatContainer = chatContainerRef.current;
        if (chatContainer) {
            const isAtBottom = chatContainer.scrollHeight - chatContainer.scrollTop === chatContainer.clientHeight;
            const threshold = 500; // Distance from the bottom to consider the user has scrolled up
            setIsScrolledUp(chatContainer.scrollHeight - chatContainer.scrollTop > chatContainer.clientHeight + threshold);

            // If the user is at the bottom, hide the "scroll to bottom" icon
            if (isAtBottom) {
                setShowScrollToBottom(false);
            }
        }
    };

    // Handle inbound messages (for external triggers)
    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            if (event.origin !== window.location.origin) return;
            const message = event.data;
            if (message === 'Chat Opened') {
                scrollToBottom();
            }
        };

        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    function sendMessageToParent(msg: string) {
        window.parent.postMessage(msg, window.location.origin);
    }

    return (
        <div className='chat-body'>
            <div className="chat-header-container">
                <h3 className="chat-pill">Chat</h3>
                <h2 className="studio-title"><span style={{ fontWeight: 'bold' }}>Sole</span> Studio</h2>
            </div>
            <div className='users-online'>
                <UsersOnline />
            </div>
            <div className="chat-container" ref={chatContainerRef} onScroll={handleScroll}>
                <ul>
                    {
                        messageRef.current.map((msg, index) => {
                            const lastMessageSameUser = index > 0 && messageHistory[index - 1].playerId === msg.playerId;
                            const nextMessageSameUser = index < messageHistory.length - 1 && messageHistory[index + 1].playerId === msg.playerId;
                            const isFirstMessage = !lastMessageSameUser;
                            const isLastMessage = !nextMessageSameUser;

                            return (
                                <ChatBubble
                                    key={index + "bubble"}
                                    index={index}
                                    className="user-chat-bubble"
                                    msg={msg}
                                    timeClassName="user-bubble-timestamp"
                                    lastMessageSameUser={lastMessageSameUser}
                                    isFirstMessage={isFirstMessage}
                                    isLastMessage={isLastMessage}
                                />
                            );
                        })
                    }
                </ul>
            </div>
            {/* Display the scroll to bottom icon */}
            {showScrollToBottom && (
                <div className="scroll-to-bottom-div">
                     <button className="scroll-to-bottom" onClick={scrollToBottom}>
                     ↓ New Messages
                </button>
           
                    </div>
               )} 
            <div className='is-typing-container'>{isTyping}</div>
            <ChatInput sendMessageToParent={sendMessageToParent} setIsFocused={setIsFocused} room={room} />
        </div>
    )
}

export default ChatWindow;
