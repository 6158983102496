import { Route, Routes} from 'react-router-dom';
import './App.css';
import React, { Fragment, useEffect } from "react";
import ReadyPlayerMeCreator from './components/ReadyPlayerMe/ReadyPlayerMeCreator';
import { Landing3D } from './components/LoadingPage/Landing3D';
import { InboundLinkRouting } from './components/InboundLinkRouting';

window.openChat = window.openChat ?? false;
window.playerId = window.playerId ?? ""

const App: React.FC = () => {

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        console.log('Tab is inactive');
      } 
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  
  return (
      <Fragment>
          
                <Routes> 
                    <Route
                      path="/"
                      element={<Fragment>
                          <Landing3D/>  
                        </Fragment>}
                    />
                    <Route
                      path="/mv/studio/:id"
                      element={<InboundLinkRouting path='studio'/>}
                    />
                    <Route
                      path="/mv/studio"
                      element={<InboundLinkRouting path='studio'/>}
                    />
                      <Route
                      path="/mv/hub/:id"
                      element={<InboundLinkRouting path='hub'/>}
                    />
                    <Route
                      path="/mv/hub"
                      element={<InboundLinkRouting path='hub'/>}
                    />
                    <Route 
                      path="/mv/editor"
                      element={<ReadyPlayerMeCreator 
                                  style={{ width: "100vw", height: "100vh", border: "none", margin: 0, zIndex:4500000, borderRadius: 'none' }} 
                                  width='100vw' 
                                  height='100vh'
                                  close={null}
                                  isModal={false}
                                  sendMessage={null}
                                  />}
                    />
                </Routes>
        </Fragment>
  );
}

export default App;