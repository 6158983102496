import { createSlice} from "@reduxjs/toolkit";
import { PartyMessage } from "../components/LiveChat/partyComponents/PartyTypes";

export interface Message  {
    from:string,
    playerId: string,
    msg: string, 
    timestamp: string,
    avatar: string,
    partyInfo?: PartyMessage,
    responded?: boolean
}

export interface MessageImages {
    playerId: string,
    imageObjecturl: string
}

interface MessageState {
    messageHistory: Message[],
    images: MessageImages[],
    unityMessage: string
}

const initialState: MessageState = {
    messageHistory: [
        {   from: "System", 
            playerId: "54321",
            msg: "Welcome to Chat", 
            timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }), 
            avatar: "https://models.readyplayer.me/66a7a3fc922f759d2bf57a7b.png"
        }
    ],
    images: [
        {
            playerId: "54321",
            imageObjecturl: "https://models.readyplayer.me/66a7a3fc922f759d2bf57a7b.png"
        }
    ],
    unityMessage: ""
}

export const messagesSlice = createSlice({
    name:"messages",
    initialState,
    reducers: {
        setMessageHistory: (state, action) => {
            state.messageHistory = action.payload
        },
        setImages: (state, action) => {
            state.images = action.payload
        },
        setUnityMessage: (state, action) => {
            state.unityMessage = action.payload
        }
    }
});

export const { setMessageHistory, setImages, setUnityMessage } = messagesSlice.actions;

export default messagesSlice.reducer;