import { PulsingDiv } from "./PulsingDiv"
import CirclesAnim from "./CirclesAnim"
import React, { useState } from "react"
import { WindowSize } from "../AvatarPage/AvatarPage";

const LoadingPage: React.FC = () =>{

    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: window.innerWidth,
        height: window.innerHeight
      });
    const circleScalingFactor = Math.min(windowSize.width / 1800, windowSize.height / 1800);

    return(
        <div style={{height: '100vh', width: '100wh'}}>

              <div className="dotted dot1 offset" style={{ height: 1700 * circleScalingFactor, width: 1700 * circleScalingFactor }}>

            </div>
            <div className="circle  offset" style={{ height: 1300 * circleScalingFactor, width: 1300 * circleScalingFactor}}>
                <div className="dotted dot1 offset" style={{ height: 1200 * circleScalingFactor, width: 1200 * circleScalingFactor }}></div>
                <img id="logoImage" src={"/images/worldAsOne.png"} alt="no image" className="avatar-logo" height={530 * circleScalingFactor} width={550 * circleScalingFactor}  />
                    <div className="loading-text">Loading<span className="dots"></span></div>
            </div>
        </div>
    )
}

export default React.memo(LoadingPage);