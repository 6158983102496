import { useEffect, useState } from "react"
import { RouteParams, UnityClient } from "./UnityClient";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { AvatarPage } from "./AvatarPage/AvatarPage";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setPlayer } from "../store/appUser";
import { setLoader, setData, setFramework, setCode, setStreamingAssets, setName, setModuleId } from "../store/scenes";

export const globalIsHost = {
    isHost: false
} 

type Props = {
    path: string
}

export const InboundLinkRouting: React.FC<Props> = ({path}) => {

    const {id} = useParams<RouteParams>();
    const { sceneTiles, loader,data,framework,code,streamingAssests,moduleId } = useAppSelector(state => state.scenes)
    const [hasCookie, setHasCookie] = useState(true);
    const bypassId = "2914dced-44ad-4ed4-8d69-1e82af4e09bc";
    const fbxId = "bb9fd5bd-fd80-4524-8355-8c765d5510d8";
    const hostId = "b2124128-a607-487f-b9de-33b03750cab9";
    const redirect = "5ebe8489-1be0-46d8-a4bd-52645d78440e";
    const dispatch = useAppDispatch();
  
    useEffect(() => {
        if (path === "studio") {
            dispatch(setLoader(sceneTiles[0].loader));
            dispatch(setData(sceneTiles[0].data));
            dispatch(setFramework(sceneTiles[0].framework));
            dispatch(setCode(sceneTiles[0].code));
            dispatch(setStreamingAssets(sceneTiles[0].streamingAssests));
            dispatch(setName(sceneTiles[0].name));
            dispatch(setModuleId(sceneTiles[0].moduleId));
        }
        if (path === "hub") {
            dispatch(setLoader(sceneTiles[1].loader));
            dispatch(setData(sceneTiles[1].data));
            dispatch(setFramework(sceneTiles[1].framework));
            dispatch(setCode(sceneTiles[1].code));
            dispatch(setStreamingAssets(sceneTiles[1].streamingAssests));
            dispatch(setName(sceneTiles[1].name));
            dispatch(setModuleId(sceneTiles[1].moduleId));
        }
    }, [path, dispatch]); 
    
    
    useEffect(() => {
        if(id !== redirect){
            fetchData(window.location.origin + "/user-profile")
            .then(data => {
                console.log('Data received:', data);

                dispatch(setPlayer(data))
                if(data.glbUrl === null || data.displayName === null){
                    setHasCookie(false)
                }
                if(data.glbUrl){
                    let modelId = data.glbUrl?.split('/').pop()?.split('.')[0] ?? null;
                    window.avatarId = modelId
                }
              })
              .catch(error => {
                  console.error('Error:', error);
                  setHasCookie(false)
            });
        }
  }, []);


    // probably wont need this as is Cookie Based
    // useEffect(() => {
    //     let avatarUrl = Cookies.get("avatarUrl");
    //     let username = Cookies.get("username");

    //     if (avatarUrl === undefined || username === undefined) {
    //         setHasCookie(false);
    //     }
    // }, []);

   

    async function fetchData(url: string): Promise<any> {
        try {
            const response = await fetch(url, {
                method: 'GET', 
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error; 
        }
    }

    if ((id === bypassId || id === fbxId) && loader && data && framework && code && streamingAssests && moduleId) {
    
        return <UnityClient id={id} />;
    }
    
    if(id === hostId){
        globalIsHost.isHost = true
    }

    return(
        <>
            {loader && data && framework && code && streamingAssests && moduleId && (
                hasCookie ? <UnityClient id={id}/> : <AvatarPage redirect={setHasCookie}/>
            )}
        </>
        

    )
}