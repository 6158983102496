import React, { useEffect, useState } from "react";
import "./Debug.css"
import Cookies from "js-cookie";
import { Refresh } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setBrowserDetails,setIp } from "../../store/debug";

type Props = {
  setShowDebug: Function
}
export const DebugPanel: React.FC<Props> = ({setShowDebug}) => {
    
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [activeAudioInput, setActiveAudioInput] = useState<string | null>(null);
  const username = Cookies.get("username")
  const { browserDetails, ip, websocket } = useAppSelector(state => state.debug)
  const { player } = useAppSelector(state => state.appUser)
  const dispatch = useAppDispatch()

  useEffect(() => {
    const getBrowserDetails = () => {
      const { userAgent } = navigator; 
      dispatch(setBrowserDetails(
        userAgent
      ));
    };

    getBrowserDetails();
  }, []);

  useEffect(() => {
    async function getPublicIP() {
      try {
          let response = await fetch('https://api.ipify.org?format=json');
          let data = await response.json();
          dispatch(setIp(data.ip))
      } catch (error) {
          console.error('Error fetching the IP address:', error);
      }
  }

  getPublicIP();
  }, [])


  const listMediaDevices = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });

      const devices = await navigator.mediaDevices.enumerateDevices();
      setDevices(devices);

      const audioTrack = stream.getAudioTracks()[0];
      setActiveAudioInput(audioTrack.getSettings().deviceId || null);

 
    } catch (error) {
      console.error('Error accessing media devices:', error);
    }
  };

  function handleSendDiagnostics(){
      console.log("Sending")
  }

  /*
    note table items commented out are awating that information to be supplied by unity  
    except the send btn which is awaiting and endpoint to send the data to
  */
    
    return(
        <div className="debug-panel">
                <h1 id="debug-heading">Diagnostics</h1>
                <p id="debug-close" onClick={() => setShowDebug(false)}>X</p>
                <div id="devices">
                    <table>
                        <tr>
                          <td>Player ID</td>
                          <td>{player.playerId}</td>
                        </tr>
                        <tr>
                          <td>Player IP</td>
                          <td>{ip}</td>
                        </tr>
                        <tr>
                          <td>Display Name</td>
                          <td>{username}</td>
                        </tr>
                        <tr>
                          <td>Browser</td>
                          <td>{browserDetails}</td>
                        </tr>
                        {devices.length > 0 ? devices.map((device) => (
                          device.deviceId === activeAudioInput && (
                            <tr key={device.deviceId} >
                              <td>{device.kind}</td>
                              <td>{device.label || 'Label not available'}</td>  
                          </tr>)
                        )) :
                          <><tr>
                              <td>Audio Input</td>
                              <td><button className="debug-btn" onClick={() => listMediaDevices()}><Refresh/></button></td>
                            </tr>
                            <tr>
                              <td>Audio Output</td>
                              <td><button className="debug-btn" onClick={() => listMediaDevices()}><Refresh/></button></td>
                            </tr></>
                        }
                        {/* <tr>
                          <td>FPS</td>
                          <td>60</td>
                        </tr> */}
                        {/* <tr>
                          <td>Unity Websocket</td>
                          <td>{unityWebsocket}</td>
                        </tr> */}
                          <tr>
                          <td>Websocket</td>
                          <td>{websocket}</td>
                        </tr>
                        {/* <tr>
                          <td>Unity Version</td>
                          <td>{unityVersion}</td>
                        </tr>
                        <tr>
                          <td>Web Server Version</td>
                          <td>{webServerVersion}</td>
                        </tr>
                        <tr>
                          <td>React Version</td>
                          <td>{reactVersion}</td>
                        </tr> */}
                    </table>
                    
                </div>
                {/* <button
                  id="debug-send-btn"
                  onClick={() => handleSendDiagnostics()}
                ></button> */}
        </div>
    )
}