import {
    AvatarCreator,
    AvatarCreatorConfig,
  } from "@readyplayerme/react-avatar-creator";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { cleanMessage } from "../LiveChat/chatWindowComponents/ChatBubble";
import { useAppSelector } from "../../store/hooks";
import { setPlayer } from "../../store/appUser";
import { setMessageHistory } from "../../store/messages";

  type Props = {
    style: {
      width: string,
      height: string, 
      border: string, 
      margin: number, 
      zIndex: number,
      borderRadius: string
    },
    width: string,
    height: string,
    close: Function | null,
    isModal: boolean,
    sendMessage: Function | null
  }

  const ReadyPlayerMeCreator: React.FC<Props> = ({style, width, height, close, isModal,sendMessage}) => {
    
    const dispatch = useDispatch()
    const history = useNavigate()
    const { player, id } = useAppSelector((state) => state.appUser)
    const {messageHistory} = useAppSelector((state) => state.messages)
    const [showBtns, setShowBtns] = useState(false)
    const [newName, setNewName] = useState(player.displayName)
    const playerRef = useRef(player)
    const playerIdRef = useRef(id)
    const messageHistoryRef = useRef(messageHistory)
    const newNameRef = useRef(newName)
    
    const config: AvatarCreatorConfig = {
      clearCache: false,
      bodyType: "fullbody",
      quickStart: false,
      language: "en",
      avatarId: window.avatarId ? window.avatarId : ""
    };
    

    useEffect(() => {
      playerRef.current = player
      messageHistoryRef.current = messageHistory
      newNameRef.current = newName
      playerIdRef.current = id
    },[player, messageHistory, newName, id])
    
    // useEffect(() => {
    //   let avatarUrlCookie = Cookies.get("avatarUrl")
    //   if(avatarUrlCookie){
    //     let modelId = avatarUrlCookie?.split('/').pop()?.split('.')[0] ?? null;
    //     window.avatarId = modelId
    //   }
    //   console.log("Cookie: " + avatarUrlCookie)
    //   let nameCookie = Cookies.get("username")
    //     if(nameCookie){
    //         dispatch(setAvatarName(nameCookie))
    //     }
    // },)
    

    const handleOnUserSet = (event: any) => {
      console.log(`User Ready Player me ID is: ${event.data.id}`);
    };
  
    const handleOnAvatarExported = (event: any) => {
      let url = event.data.url
      let stateToUpdate = {
        ...playerRef.current,
        glbUrl: url
      };
      console.log("UPDATING AVATAR")
      console.log(stateToUpdate)
      dispatch(setPlayer(stateToUpdate))
      let modelId = url.split('/').pop()?.split('.')[0] ?? null;
      window.avatarId = modelId

      if(isModal && close){
        if(sendMessage){
          let data = {
            displayName: playerRef.current.displayName,
            avatarUrl: url,
            avatarProfilePic: url.replace(".glb", ".png"),
            colour: playerRef.current.colour
          }
          console.log("DATA")
          console.log(data)
          sendMessage("ReactClientManager", "SetLocalAvatar",  JSON.stringify(data));
        } 
        close(false)
      } else {
        if(playerRef.current.displayName){
          history("/mv/studio/5ebe8489-1be0-46d8-a4bd-52645d78440e")
        } else {
          history("/mv/studio")
        }
        
      }

    };
  
    const handleUserAuthorized = (event: any) => {
      console.log(`User is:`, event.data);
    };
  
    const handleAssetUnlocked = (event: any) => {
      console.log(`Asset unlocked is: ${event.data.assetId}`);
    };

    
    function handleAvatarName(name:string){
      if(name.length > 0){
        setShowBtns(true)
      } else {
        setShowBtns(false)
      }
      let clean = cleanMessage(name)
      setNewName(clean)
    }

    function updateNameOnly(){
      //let nameCookie = Cookies.get("username")
      
      if(sendMessage != null && newNameRef.current != "" && newNameRef.current != null){
        let data = {
          displayName: newNameRef.current,
          avatarUrl: playerRef.current.glbUrl,
          avatarProfilePic: playerRef.current.glbUrl?.replace(".glb", ".png"),
          colour: playerRef.current.colour
        }
        sendMessage("ReactClientManager", "SetLocalAvatar",  JSON.stringify(data));
        let stateToUpdate = {
          ...playerRef.current,
          displayName: newNameRef.current
        };
        dispatch(setPlayer(stateToUpdate))
        setShowBtns(false)
        let updatedMessages = messageHistoryRef.current.map(message => {
          if (message.playerId === playerIdRef.current ) {
            return { ...message, from: newNameRef.current }; 
          }
          return message; 
        });
        
        dispatch(setMessageHistory(updatedMessages));
      }
      if(close){
        close(false)
      } 
  }

  return (
    <div style={{ width: width, height: height, position: 'relative' }}>
      {window.avatarId !== null && (
       <div style={{ position: 'relative' }}>
       <AvatarCreator
         subdomain="unityclientpage.readyplayer.me/avatar?frameApi="
         config={config}
         style={style}
         onAvatarExported={handleOnAvatarExported}
         onUserAuthorized={handleUserAuthorized}
         onAssetUnlock={handleAssetUnlocked}
         onUserSet={handleOnUserSet}
       />
     
       {showBtns && (
         <div
           style={{
             position: 'absolute',
             top: 0,
             left: 0,
             width: '111.1%',
             height: '100%',
             backgroundColor: 'rgba(128, 128, 128, 0.5)',
             pointerEvents: 'auto',
             zIndex: 10, 
             borderRadius: '15px'
           }}
         />
       )}
     </div>
     
      )}
    {isModal ? (
      <div style={{ position: 'absolute', top: '120%', right: '-8%', zIndex: 0 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <label
            htmlFor="avatarName"
            style={{
              color: 'rgb(100,234,255)',
            }}
          >
            Update Name:
          </label>
          <input
            type="text"
            className="custom-input"
            value={newName ? newName : ""}
            onChange={e => handleAvatarName(e.target.value)}
            style={{
              marginLeft: '25px',
            }}
          />
          <button
            className="close-button-rpm"
            style={{ visibility: showBtns ? 'visible' : 'hidden' }}
            onClick={() => updateNameOnly()}
          >
            <img src="/images/buttons/check.png" alt="check" width={20}/>
          </button>
          <button
            className="close-button-rpm"
            style={{ visibility: showBtns ? 'visible' : 'hidden' }}
            onClick={() => {
              if (close) {
                setNewName(player.displayName)
                setShowBtns(false)
              }
            }}
          >
            <img src="/images/buttons/cross.png" alt="cross" width={20} style={{marginLeft: '5px'}}/>
          </button>
        </div>
      </div>
    ) : null}

    </div>
  );
};
  
  export default ReadyPlayerMeCreator;