import { createSlice} from "@reduxjs/toolkit";

interface SceneState {
    name: string,
    moduleId: string,
    loader: string,
    data: string,
    framework: string,
    code:string,
    streamingAssests: string,
    sceneTiles: SceneTiles[]
}


export interface SceneTiles {
    name: string,
    moduleId: string,
    image: string,
    loader: string | undefined,
    data: string | undefined,
    framework: string | undefined,
    code:string | undefined,
    streamingAssests: string | undefined,
}

const initialState: SceneState = {
    name: "",
    moduleId: "",
    loader: "",
    data: "",
    framework: "",
    code:  "",
    streamingAssests: "",
    sceneTiles: [
        {
            name: "Soledad Studio",
            moduleId: "4ec94d65-4127-45da-9032-54d0a3f255c8",
            image: "",
            loader: process.env.REACT_APP_UNITY_BUILD_FILE_LOADER_GENNIUS_SOLEDAD_STUDIO,
            data: process.env.REACT_APP_UNITY_BUILD_FILE_DATA_GENNIUS_SOLEDAD_STUDIO,
            framework: process.env.REACT_APP_UNITY_BUILD_FILE_FRAMEWORK_GENNIUS_SOLEDAD_STUDIO,
            code:process.env.REACT_APP_UNITY_BUILD_FILE_WASM_GENNIUS_SOLEDAD_STUDIO,
            streamingAssests: process.env.REACT_APP_UNITY_BUILD_FILE_STREAMING_ASSETS_GENNIUS_SOLEDAD_STUDIO,
        },
        {
            name: "The Hub",
            moduleId: "804d21fc-0d53-4376-ae5a-30b751791986",
            image: "",
            loader: process.env.REACT_APP_UNITY_BUILD_FILE_LOADER_GENNIUS_HUB,
            data: process.env.REACT_APP_UNITY_BUILD_FILE_DATA_GENNIUS_HUB,
            framework: process.env.REACT_APP_UNITY_BUILD_FILE_FRAMEWORK_GENNIUS_HUB,
            code:process.env.REACT_APP_UNITY_BUILD_FILE_WASM_GENNIUS_HUB,
            streamingAssests: process.env.REACT_APP_UNITY_BUILD_FILE_STREAMING_ASSETS_GENNIUS_HUB,
        }
    ]
}

export const sceneStateSlice = createSlice({
    name:"scenes",
    initialState,
    reducers: {
        setSceneState: (state, action) => {
            state = action.payload
        },
        setName: (state, action) => {
            state.name = action.payload
        },
        setModuleId: (state, action) => {
            state.moduleId = action.payload
        },
        setLoader: (state, action) => {
            state.loader = action.payload
        },
        setData: (state, action) => {
            state.data = action.payload
        },
        setFramework: (state, action) => {
            state.framework = action.payload
        },
        setCode: (state, action) => {
            state.code = action.payload
        },
        setStreamingAssets: (state, action) => {
            state.streamingAssests = action.payload
        }
    }
});

export const { setName, setModuleId, setCode,setData,setFramework,setLoader,setSceneState,setStreamingAssets } = sceneStateSlice.actions;

export default sceneStateSlice.reducer;