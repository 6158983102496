import * as React from 'react';
import Box from '@mui/material/Box';
import { useSpring, animated } from '@react-spring/web';
import { useAppDispatch } from '../../store/hooks';
import ReadyPlayerMeCreator from './ReadyPlayerMeCreator';

interface FadeProps {
  children: React.ReactElement;
  in?: boolean;
  onClick?: any;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  onExited?: (node: HTMLElement, isAppearing: boolean) => void;
  ownerState?: any;
}

export const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null as any, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null as any, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

export type StyleType = {
    position: string;
    top: string;
    left: string;
    transform: string;
    width: string;
    boxShadow: number;
    p: number;
  };

export const style: StyleType = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  boxShadow: 24,
  p:4,
};

type Props = {
    open: boolean,
    setOpen: Function,
    sendMessage: Function
}
export const UpdateAvatarModal: React.FC<Props> = ({open,setOpen, sendMessage}) =>  {
  
  const handleClose = () => setOpen(false);
  const dispatch = useAppDispatch()



  return (
    <div style={{display: open ? "": "none", zIndex: 5}}>
          <Box component="div" className='profile-modal' sx={style}>
                <ReadyPlayerMeCreator 
                    style={{ width: "60vw", height: "57vh", border: "none", margin: 0, zIndex:4500000, borderRadius: '20px' }} 
                    height='80%' width='90%'
                    close={setOpen}
                    isModal={true}
                    sendMessage={sendMessage}
                    />
          </Box>
    </div>
  );
}