import { createSlice } from "@reduxjs/toolkit";

export interface User {
    timestamp: string,
    playerId: string,
    displayName: string,
    avatarUrl: string,
    avatarProfilePic: string | null,
    radius: number,
    height: number,
    defaultAvatarType: string,
    colour: string
}

interface UsersOnlineState {
    users: User[]
}
const initialState: UsersOnlineState = {
    users: []
}

export const usersOnlineSlice = createSlice({
    name: "usersOnline",
    initialState,
    reducers: {
        setUsers: (state, action) => {
            state.users = action.payload
        }
    }
});

export const { setUsers } = usersOnlineSlice.actions;

export default usersOnlineSlice.reducer;