import { createSlice} from "@reduxjs/toolkit";

interface DebugState {
   browserDetails: string,
   ip: string,
   websocket: string,
   unityVersion: string,
   webServerVersion: string,
   reactVersion: string
}
const initialState: DebugState = {
    browserDetails: "",
    ip: "",
    websocket: "Disconnected",
    unityVersion: "1.0",
    webServerVersion: "1.0",
    reactVersion: "1.0"
}
export const debugSlice = createSlice({
    name:"debugState",
    initialState,
    reducers: {
        setBrowserDetails: (state, action) => {
            state.browserDetails = action.payload
        },
        setIp: (state, action) => {
            state.ip = action.payload
        },
        setWebsocket: (state, action) => {
            state.websocket = action.payload
        },
        setunityVersion: (state, action )=> {
            state.unityVersion = action.payload
        },
        setReactVersion: (state, action) => {
            state.reactVersion = action.payload
        },
        setWebServerVersion: (state, action) => {
            state.webServerVersion = action.payload
        }
    }
});

export const { setBrowserDetails,setIp,setReactVersion,setWebsocket,setWebServerVersion,setunityVersion } = debugSlice.actions;

export default debugSlice.reducer;